body {
  margin: 0;
  padding: 0;
  font-family: "Avenir Next", Raleway;
  background: #ffffff;
  color: #18181a;
  /* background: url("./images/back10.jpg"); */
  background-size: 28%;
  height: 100%;
  /* opacity: 0.98; */
}
body * {
  /* transition: all 0.100s ease-out; */
}

a {
  text-decoration: none;
  color: inherit;
}

@font-face {
  font-family: "Avenir Next";
  src: url("./AvenirNext-Regular.4d8fdeb2.ttf") format("truetype");
}

@font-face {
  font-family: "DM Sans";
  src: local("DM Sans"), url(./assets/fonts/DMSans-Regular.ttf) format("woff");
}
.MuiTableCell-root {
  padding: 0;
}
.github {
  background: rgba(173, 173, 173, 0.22);
  color: #1d1c1c;
  font-weight: 900;
  font-size: 1em;
  width: 80%;
  max-width: 170px;
  text-align: center;
  padding: 10px;
  border-radius: 5px;
  text-decoration: none;
  box-shadow: rgba(51, 51, 51, 0.11) 0px 4px 10px 0px;
  display: flex;
  flex-direction: row;
  margin-bottom: 20px;
}
.image_picker {
  display: flex;
  position: relative;
}

.image_picker .responsive {
  position: relative;
  margin: 0 6px;
  opacity: 1;
}

.image_picker .selected {
  opacity: 0.8;
}

.image_picker .disabled {
  opacity: 0.5;
}

.image_picker .responsive .selected {
  background-color: #8aaaeb;
}

.image_picker > .responsive > .thumbnail {
  margin-left: 10px;
  margin-right: 10px;
}

.image_picker .responsive .thumbnail {
  border: 1px solid #62626291;
  border-radius: 10px;
  padding: 2px;
  width: 150px;
  opacity: 1;
  margin: 0;
}

.image_picker .responsive .checked {
  display: none;
}

.image_picker .selected .checked {
  display: inline-block;
  transition: 0.5s ease;
  position: absolute;
  bottom: 10px;
  right: 10px;
}

.image_picker .selected .checked .icon {
  background: url("https://res.cloudinary.com/dfcnic8wq/image/upload/v1605594996/FlowDash/PngItem_2408141_pplbnw.png");
  background-size: cover;
  height: 25px;
  width: 25px;
}

.container {
  background-color: #fff;
  width: 100%;
  max-width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  /* -webkit-box-shadow: 0px 10px 54px -11px rgba(0,0,0,0.75);
-moz-box-shadow: 0px 10px 54px -11px rgba(0,0,0,0.75);
box-shadow: 0px 10px 54px -11px rgba(0,0,0,0.75); */
}

.login-container {
  background: #f7f7f72e; /* -webkit-box-shadow: 0px 10px 54px -11px rgba(0,0,0,0.75); -moz-box-shadow: 0px 10px 54px -11px rgba(0,0,0,0.75); box-shadow: 0px 10px 54px -11px rgba(0,0,0,0.75); */
  background-size: 100%;
  background-position-y: center;
  height: 85vh;
  opacity: 0.89;
  background-repeat: repeat-y;
}
.react-quiz-container {
  padding: 50px !important;
  margin: 20px auto !important;
  width: 80% !important;
  background: #e8e8ec24;
  min-height: 80vh;
  overflow-y: scroll;
  font-family: inherit !important;
  font-weight: 300 !important;
}
.explanation {
  padding: 20px;
}

.insights {
  word-break: break-all;
  background: #f7f7f778;
  padding: 20px !important;
  border-radius: 10px;
}

.timer {
  display: flex;
  flex-direction: row-reverse;
}
.time {
  display: flex;
  padding: 5px;
  border-radius: 5px;
  background: #e8e8ec;
  font-size: 1.1em !important;
  font-weight: 400 !important;
}

.oot {
  color: #c33c3c;
}

.question-timeline {
  display: flex;
  justify-content: space-evenly;
  /* border-radius: 8px; */
  /* padding: 10px; */
  margin-left: 7%;
  margin-right: 7%;
  overflow-x: auto;
  align-items: center;
  border-bottom: 2px solid #e5e5e5;
}

.request-access-form {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
}

.inarena {
  position: absolute;
  grid-template-columns: 0.2fr 2fr 0.3fr !important;
}

.question-index {
  padding: 25px;
  font-size: 0.9em;
  /* background: #a8c7f1; */
  /* height: 20px; */
  /* width: 20px; */
  cursor: pointer;
  color: #1c1f28;
  text-align: center;
  /* border-radius: 50%; */
  /* border: 2px solid #95b7f9; */
}

.MuiMenu-paper {
  color: rgb(40, 35, 84) !important;
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0),
    0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0) !important;
}

.visited {
  background: #b3cbe0;
}
.visited-not-selected {
  background: #da6d6d;
}
.active-ques {
  color: #578fff;
  border-bottom: 2px solid #578fff;
}

/*------------- NAVIGATION BAR -------------------*/

nav {
  position: fixed;
  z-index: 99;
  background: #fff;
  z-index: 99;
  width: 100%;
  margin: 0 auto;
  padding: 12px 0;
  display: grid;
  top: 0;
  grid-template-columns: 2fr 0.5fr;
  /* -webkit-box-shadow: 0px 13px 13px -18px rgba(0,0,0,0.75);
-moz-box-shadow: 0px 13px 13px -18px rgba(0,0,0,0.75);
box-shadow: 0px 13px 13px -18px rgba(0,0,0,0.75); */
}

nav ul {
  width: 100%;
  display: grid;
  list-style: none;
  align-items: center;
  padding: 0;
  margin: 0;
}

nav ul:first-of-type {
  grid-template-columns: 1fr;
}
nav ul:first-of-type li {
  font-size: 1.8rem;
  font-weight: 500;
  text-indent: 30px;
  margin-right: 20px;
  color: #090623;
}
nav ul:first-of-type li span {
  font-weight: 900;
  color: #090623 !important;
}

nav ul:last-of-type {
  grid-template-columns: 0.8fr 0.8fr;
}

nav ul li a {
  color: #ffffff;
  text-decoration: none;
  font-weight: 900;
  display: block;
  -webkit-font-smoothing: antialiased;
}

.login {
  /* border: solid thin; */
  /* border-radius: 5px; */
  padding: 6px;
  margin-left: 0px;
  margin-right: 10px;
  /* border-color: #ffcb00; */
  /* background: #f0db01; */
  /* color: #2100f0; */
  text-align: center;
}
a {
  cursor: pointer;
}
nav ul li .active {
  font-weight: bolder;
}

.rating {
  float: right;
}

.service-text {
  font-size: 1.2rem;
}

#contact {
  width: 100%;
  grid-template-columns: 1fr;
  padding: 0px;
  margin: 0px 0px;
  background: #372f71;
}
#contact .contact {
  padding: 13px;
  margin-bottom: 90px;
  max-width: 400px;
  margin-top: 100px;
  font-size: 1.8em;
}
.phone-hero {
  width: 90%;
  margin: 5px auto;
}

.phone-hero video {
  width: 100%;
}

.nav-class-active .sign-up {
  color: white !important;
  background: rgb(138 171 235);
  box-shadow: rgba(51, 129, 218, 0.25) 0px 4px 10px 0px;
}

.nav-class-active .docs {
  box-shadow: rgba(51, 51, 51, 0.11) 0px 4px 10px 0px;
}

/*------------- HEADER -------------------*/

header {
  height: 100%;
  background-position: bottom;
  position: relative;
  background-repeat: no-repeat;
  background-size: 100%;
  z-index: 4;
  margin-bottom: 140px;
}

.feedback {
  background: #f3f5fb;
  width: 90%;
  border-radius: 15px;
  text-align: center;
}

header div.head {
  position: relative;
  z-index: 2;
  display: grid;
  width: 80%;
  grid-template-columns: 1.1fr 1fr;
  margin: 0 auto;
  padding-top: 5%;
  align-items: center;
}

header div.head {
  color: hsl(0, 0%, 20%);
}

.app-sidenav {
  margin-top: 65px;
  border: 1px solid #ebecf2;
  height: 100vh;
  overflow-y: scroll;
}

.account-section {
  position: absolute;
  bottom: 25px;
}

.topics-list {
  width: 100%;
  grid-template-columns: 1fr;
  background: #fafafa;
  padding: 0px;
  margin-top: 40px !important;
  /* box-shadow: 0px 20px 6px -22px rgba(0.75,0.75,0.75,0.75); */
}

.MuiTabs-flexContainer {
  display: flex;
  justify-content: space-around !important;
}

.sign-up {
  color: #999999;
  padding: 8px;
  text-align: center;
  /* background: rgb(56, 142, 241); */
  border-radius: 10px;
  margin-right: 20px;
  margin-left: 20px;
  font-size: 1em;
  /* box-shadow:rgba(51, 129, 218, 0.25) 0px 4px 10px 0px; */
}

.custom-edit-button {
  background: #fafafa;
  border: none;
  color: #9b9b9b;
  cursor: pointer;
}

@keyframes animationCollapse {
  0% {
    grid-template-columns: "0.1fr 1fr";
  }
  100% {
    grid-template-columns: "0.06fr 1fr";
  }
}
@keyframes animationExpand {
  100% {
    grid-template-columns: "0.1fr 1fr";
  }
  0% {
    grid-template-columns: "0.06fr 1fr";
  }
}
.hljs {
  background: #fafafa !important;
  padding: 20px !important;
  border-radius: 10px !important;
}

.clipWrapper button {
  color: rgb(87, 143, 255);
  font-weight: 900;
  top: 10px !important;
  right: 10px !important;
  cursor: pointer;
}

.docs {
  color: #999999;
  padding: 8px;
  text-align: center;
  /* background: rgb(56, 142, 241); */
  border-radius: 6px;
  margin-right: 20px;
  margin-left: 20px;
  font-size: 1em;
  /* box-shadow:rgba(51, 129, 218, 0.25) 0px 4px 10px 0px; */
}

main section img {
  width: 100%;
}

.logo {
  margin-right: auto !important;
}

@import url(https://fonts.googleapis.com/css?family=Open+Sans:400italic);
blockquote {
  font-size: 1em;
  width: 100%;
  margin: 50px auto;
  font-family: Open Sans;
  font-style: italic;
  color: #555555;
  padding: 1.2em 30px 1.2em 75px;
  border-left: 5px solid #7c83aa;
  line-height: 1.6;
  position: relative;
  background: #ededed;
}

code {
  background: #ededed;
  padding: 5px;
}

.preview section {
  grid-template-columns: 1fr;
}
blockquote span {
  display: block;
  color: #333333;
  font-style: normal;
  font-weight: bold;
  margin-top: 1em;
}

nav ul li a:hover {
  font-weight: 700;
  /* border-bottom: 1px solid #4e44a0; */
}

header div.head h1 {
  font-size: 2.2rem;
  font-weight: bolder;
  /* text-transform: uppercase; */
  padding-top: 0px;
  margin: 10px 10px 10px 0px;
  -webkit-font-smoothing: antialiased;
  line-height: 1.5;
  /* letter-spacing: 4px; */
  /* border-right: 2px solid #ece7e7b8; */
}

header div.head p {
  padding-left: 30px;
  color: #111111;
}

header div.head h1 span {
  color: #999999;
  font-weight: 900;
  font-size: 1.4rem;
}

.nav-class-active {
  background-color: white;
  box-shadow: 0px 13px 13px -23px rgba(0, 0, 0, 0.4);
  grid-template-columns: 2fr 0.5fr;
  border-bottom: 1px solid #ebecf2;
  position: fixed;
}

.nav-class {
  background-color: transparent;
}

.nav-class nav ul:last-of-type {
  grid-template-columns: 0.8fr 0.2fr 0.8fr 0.2fr 0.9fr 0.5fr;
}

.nav-class-active .logo {
  color: #090623;
}

.nav-class-active .mainNav {
  grid-template-columns: 0.8fr 0.8fr;
}

.fa-bolt {
  color: #4f44a0;
  display: none;
}

.nav-class-active .fa-bolt {
  color: #443b8b;
}
.nav-class-active .mainNav a {
  color: white;
}

.nav-class-active ul:first-of-type li span {
  color: #090623;
}

.footer-contact {
  background: #ff9080;
  padding: 8px 18px 8px 18px;
  border-radius: 10px;
  margin-bottom: 20px;
  box-shadow: rgba(51, 129, 218, 0.14) 0px 4px 10px 0px;
  cursor: pointer;
}

.footer-contact:hover {
  /* background: hsl(8, 89%, 76%); */
  transform: translateY(-1px);
}

.contact {
  background: rgb(138 171 235);
  color: #ffffff;
  font-weight: 900;
  font-size: 1em;
  width: 50%;
  text-align: center;
  padding: 15px;
  border-radius: 10px;
  margin-left: 10px;
  text-decoration: none;
  box-shadow: rgba(51, 129, 218, 0.25) 0px 4px 10px 0px;

  /* -webkit-animation-name: wiggle;
  -ms-animation-name: wiggle;
  -ms-animation-duration: 1000ms;
  -webkit-animation-duration: 1000ms;
  -webkit-animation-iteration-count: 1;
  -ms-animation-iteration-count: 1;
  -webkit-animation-timing-function: ease-in-out;
  -ms-animation-timing-function: ease-in-out; */
}

.contact:hover {
  background: rgb(50, 122, 204);
  transform: translateY(-2px);
}

.request-access {
  width: 100%;
  margin-top: 0px;
  margin-bottom: 0px;
  margin-right: 10px;
  font-size: 1rem;
  color: rgb(31, 41, 51);
  /* box-shadow: rgb(203, 210, 217) 0px 0px 0px 1px, rgba(31, 41, 51, 0.2) 0px 2px 3px -2px inset; */
  -webkit-appearance: none;
  resize: none;
  padding: 0.75rem;
  background: rgb(255, 255, 255);
  border-width: initial;
  border-style: none;
  border-color: initial;
  border-image: initial;
  border-radius: 3px;
  transition: all 0.1s ease 0s;
}
.seemore {
  background: #4b4b4c;
  display: block;
  color: #fff;
  font-weight: 500;
  width: 80%;
  margin: 0 auto;
  text-align: center;
  padding: 12px;
  border-radius: 10px;
  text-decoration: none;
  -webkit-box-shadow: 0px 10px 20px -11px rgba(0, 0, 0, 0.9);
  -moz-box-shadow: 0px 10px 20px -11px rgba(0, 0, 0, 0.9);
  box-shadow: 0px 10px 20px -11px rgba(0, 0, 0, 0.9);
  box-shadow: 0px 1px 24px 2px rgba(27, 23, 59, 0.05),
    0px 1px 4px 0px rgba(27, 23, 59, 0.11),
    0px 17px 12px -12px rgba(27, 23, 59, 0.28);
}

.card-body {
  min-width: 0px;
  margin: 0 auto;
  max-width: 90%;
}

.locateme {
  background: #362f6e;
  display: block;
  color: #ffffff;
  font-weight: 500;
  width: 28%;
  margin: 10px auto;
  text-align: center;
  padding: 12px;
  border-radius: 5px;
  text-decoration: none;
  min-width: 200px;
  max-width: 300px;
  box-shadow: 0px 1px 24px 2px rgba(27, 23, 59, 0.05),
    0px 1px 4px 0px rgba(27, 23, 59, 0.83),
    0px 17px 12px -12px rgba(27, 23, 59, 0.32);
}

.signin {
  margin: 10px auto;
  text-align: center;
  font-size: 1.8em;
}

/*---- get-started animation ----*/

@-webkit-keyframes wiggle {
  0% {
    -webkit-transform: rotate(10deg);
  }
  25% {
    -webkit-transform: rotate(-10deg);
  }
  50% {
    -webkit-transform: rotate(20deg);
  }
  75% {
    -webkit-transform: rotate(-5deg);
  }
  100% {
    -webkit-transform: rotate(0deg);
  }
}

@-ms-keyframes wiggle {
  0% {
    -ms-transform: rotate(1deg);
  }
  25% {
    -ms-transform: rotate(-1deg);
  }
  50% {
    -ms-transform: rotate(1.5deg);
  }
  75% {
    -ms-transform: rotate(-5deg);
  }
  100% {
    -ms-transform: rotate(0deg);
  }
}

@keyframes wiggle {
  0% {
    transform: rotate(10deg);
  }
  25% {
    transform: rotate(-10deg);
  }
  50% {
    transform: rotate(20deg);
  }
  75% {
    transform: rotate(-5deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

/* .makeStyles-card-1:hover { 
 box-shadow: 0px 1px 15px 0px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 2px 1px -1px rgba(0,0,0,0.12);
} */
/* 
.makeStyles-avatar-5 {
  background-color: #44b321 !important;
} */

/* .makeStyles-card-1 {
  max-width: 245px !important;
} */

.social-proof {
  text-align: center;
  margin-top: 140px;
}
.test-mode-select {
  background: #b3b3e0 !important;
  color: white !important;
}

.react-quiz-container img {
  max-width: 300px !important;
}

.test-submit {
  background: #f9bb03 !important;
  color: white !important;
}

.social-proof img {
  width: 150px;
  padding: 20px;
  margin-top: 10px;
  margin-left: 20px;
  margin-right: 20px;
  margin-bottom: 10px;
}

.fa-info-circle {
  color: #fafafa;
}

/*-------------- CUSTOM LOADER -----------------*/

/* html,body {
  margin: 0;
  padding: 0;
  font-family:'Lato', sans-serif;
} */
.loader {
  width: 100px;
  height: 80px;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  margin: auto;
}
.loader .image {
  width: 100px;
  height: 160px;
  font-size: 100px;
  text-align: center;
  transform-origin: bottom center;
  animation: 3s rotate infinite;
  opacity: 0;
  color: #6057a0;
}
.loader span {
  display: block;
  width: 100%;
  text-align: center;
  position: absolute;
  bottom: -250px;
}

.rv-xy-plot {
  margin: 0 auto;
}
.resultSuggestion {
  background: #ffffff;
  padding: 15px;
  color: #737171;
  /* text-align: center; */
  border-radius: 10px;
}
.worstTopic {
  padding: 5px;
  color: #464646;
}
@keyframes rotate {
  0% {
    transform: rotate(90deg);
  }
  10% {
    opacity: 0;
  }
  35% {
    transform: rotate(0deg);
    opacity: 1;
  }
  65% {
    transform: rotate(0deg);
    opacity: 1;
  }
  80% {
    opacity: 0;
  }
  100% {
    transform: rotate(-90deg);
  }
}
/*------------- SIDENAV BAR --------------------*/

.sidenav {
  height: 100%;
  width: 0;
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  background-color: #fff;
  overflow-x: hidden;
  transition: 0.2s;
  padding-top: 60px;
  text-align: center;
}

.sidenav a {
  padding: 8px 8px 8px 32px;
  text-decoration: none;
  font-size: 25px;
  color: #1d1d1f;
  display: block;
  transition: 0.3s;
}

.sidenav a:hover {
  color: #1d1d1f;
}

.logo-container {
  grid-template-columns: 0.22fr 2fr !important;
}

.app-logo-container {
  grid-template-columns: 2fr !important;
}

.hamburger {
  display: none;
}
.sidenav .closebtn {
  position: absolute;
  top: -15px;
  left: -20px;
  font-size: 55px;
  margin-left: 50px;
  color: #362f6e;
}

@media screen and (max-width: 1310px) {
  .sidenav {
    padding-top: 15px;
  }
  .sidenav a {
    font-size: 18px;
  }
}
/*------------- MAIN SECTION -------------------*/

main {
  position: relative;
  z-index: 3;
  overflow: hidden;
}
.assessment {
  font-size: 2em;
}

main section {
  width: 60%;
  padding: 40px 0;
  margin: 160px auto 160px auto;
  display: grid;
  grid-template-columns: 2fr 3fr;
  align-items: center;
}

main section h2 {
  font-size: 4.3rem;
  color: #444;
  padding-left: 20px;
  /* text-transform: uppercase; */
  line-height: 4.2rem;
  padding-right: 10px;
  /* border-right: 2px solid #ece7e7b8; */
  text-align: center;
}

.results {
  width: 90%;
  grid-template-columns: 1fr;
  align-items: center;
  background: #dcdcdc00;
  border-radius: 15px;
}

main section ul {
  display: grid;
  grid-template-columns: 1fr;
  list-style-type: none;
  padding: 0px;
}

main section ul li {
  padding: 6px;
}

main section div {
  position: relative;
}

main section div p {
  padding-left: 10px;
  font-size: 1.3em;
  padding-right: 20px;
}

/*------------- GALLERY -------------------*/

.gallery {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  height: 820px;
  width: 95%;
  margin: 180px auto 0px auto;
  -webkit-box-shadow: 0px 10px 20px -11px rgba(0, 0, 0, 0.9);
  -moz-box-shadow: 0px 10px 20px -11px rgba(0, 0, 0, 0.9);
  box-shadow: 0px 10px 20px -11px rgba(0, 0, 0, 0.9);
}

.gallery div {
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
  filter: grayscale(83%);
}

.gallery div.gallery-item-one:hover,
.gallery div.gallery-item-two:hover,
.gallery div.gallery-item-three:hover,
.gallery div.gallery-item-four:hover,
.gallery div.gallery-item-five:hover,
.gallery div.gallery-item-six:hover {
  background-size: 100%;
  filter: grayscale(0%);
}

/*------------- SERVICES -------------------*/

div.services {
  display: grid;
  height: auto;
  grid-template-columns: repeat(3, 1fr);
  padding: 70px;
}

.services div {
  text-align: center;
  background: rgba(243, 245, 251, 0.6);
  width: 85%;
  height: 100%;
  margin: 10px auto 10px auto;
  border-radius: 15px;
  /* -webkit-box-shadow: 0px 10px 20px -17px rgba(0,0,0,0.75); */
  /* -moz-box-shadow: 0px 10px 20px -17px rgba(0,0,0,0.75);
  box-shadow: 0px 10px 20px -17px rgba(0,0,0,0.75); */
}
.chapters {
  height: 350px;
  overflow-y: scroll;
}

.services p.service-icon {
  font-size: 4rem;
  padding: 30px 30px 30px 30px;
  margin: 0px 0px 0px 0px;
  text-align: center;
  color: #362f6e;
  filter: grayscale(0%);
  /* border-bottom: 2px solid #d1dadc; */
}
.services p.service-title {
  font-size: 1.8rem;
  padding: 0;
  margin: 0;
  font-weight: bolder;
}

.services p:last-of-type {
  padding: 40px 40px 20px 20px;
  text-align: left;
}

/*------------- FORM -------------------*/

main section form {
  padding: 0px;
  margin: 0;
}
main section h2 {
  font-size: 3rem;
}

main section form div {
  border: 1px solid #eee;
  padding: 10px;
  border-radius: 10px;
  font-size: 1.1rem;
  display: grid;
  margin-bottom: 10px;
  grid-template-columns: 1fr 3fr;
  align-items: center;
}

main section form div:last-of-type {
  display: grid;
  grid-template-columns: 3fr 0fr;
}

main section form div:last-of-type input {
  background: black;
  padding: 10px;
  color: #fff;
  border-radius: 10px;
  cursor: pointer;
  -webkit-box-shadow: 0px 10px 10px -9px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 10px 10px -9px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 10px 10px -9px rgba(0, 0, 0, 0.75);
}

main section form div label {
  background: black;
  color: #fff;
  font-weight: 500;
  font-size: 1rem;
  padding: 6px;
  text-align: center;
  margin-right: 10px;
  border-radius: 10px;
  -webkit-box-shadow: 0px 10px 10px -9px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 10px 10px -9px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 10px 10px -9px rgba(0, 0, 0, 0.75);
}

main section form div input {
  background: none;
  border: none;
  font-size: 1rem;
}
main section form div textarea {
  font-family: Raleway;

  border: none;
  background: none;
  font-size: 1rem;
  resize: vertical;
}

.error {
  color: rgb(255, 57, 57);
  font-size: 1.3rem;
}

footer {
  color: #999999;
  padding: 40px;
  margin-top: auto;
  display: grid;
  grid-template-columns: 0.7fr 2fr;
  align-items: center;
  text-align: center;
  height: auto;
  /* border-radius: 0px 0px 0px 0px; */
}

footer h3 {
  font-weight: 500;
  margin-top: -15px;
}
.questionWrapper.End {
  grid-template-columns: 1fr;
}

.questionWrapperBody {
  padding: 10px;
}

footer ul {
  list-style: none;
  display: grid;
  padding: 0;
  margin: 0;
  grid-template-columns: 1fr 1fr 1fr;
  justify-items: center;
}

footer ul li a {
  color: #fff;
}

@media (max-width: 1310px) and (min-width: 0px) {
  .mainNav {
    display: none;
  }
  .hamburger {
    display: block;
  }
  main section img {
    width: 100%;
  }
  .assessment {
    font-size: 1.8em;
  }
  .nav-class-active {
    grid-template-columns: 1fr;
  }
  .questionWrapper {
    grid-template-columns: 1fr !important;
  }

  .topics-list {
    width: 100%;
  }
  .topics-list ul {
    grid-template-columns: 1fr;
    margin: 0 auto;
    width: 90%;
  }
}
@media (max-width: 1310px) and (min-width: 1100px) {
  header div.head {
    width: 75%;
    padding-top: 190px;
  }
  header div.head h1 {
    font-size: 2rem;
    margin-bottom: 50px;
  }

  main section {
    width: 90%;
    padding: 60px 0;
    margin: 0px auto 50px auto;
    display: grid;
    grid-template-columns: 1fr;
  }
  main section div :not(.react-quiz-container)p {
    padding: 0px 90px;
  }

  main section ul {
    grid-template-columns: 1fr 1fr 1fr;
    padding: 20px;
  }

  .services p.service-icon {
    font-size: 3rem;
    padding: 10px 30px;
    margin: 0px 0px 30px 0px;
  }
  .services p.service-title {
    font-size: 2.1rem;
    padding: 0;
    margin: 0;
  }

  .services p:last-of-type {
    padding: 20px 20px;
    text-align: left;
  }

  .gallery {
    height: 600px;
  }
}

@media (max-width: 1099px) and (min-width: 850px) {
  header {
    background-position: bottom;
  }

  header div.head {
    width: 75%;
    padding-top: 120px;
    grid-template-columns: 1fr;
  }
  header div.head h1 {
    font-size: 2rem;
    margin-bottom: 50px;
  }
  main section:first-of-type {
    margin-top: 80px;
  }

  main section {
    width: 90%;
    padding: 90px 0;
    margin: 0px auto 50px auto;
    display: grid;
    grid-template-columns: 1fr;
  }
  main section div p {
    padding: 0px 90px;
  }

  main section ul {
    grid-template-columns: 1fr 1fr;
    padding: 20px;
  }

  .services p.service-icon {
    font-size: 2.4rem;
    padding: 10px 30px;
    margin: 0px 0px 30px 0px;
  }
  .services p.service-title {
    font-size: 2.1rem;
    padding: 0;
    margin: 30px;
  }

  .services p:last-of-type {
    padding: 0px 20px;
    text-align: left;
  }

  .gallery {
    height: 420px;
  }
}

@media (max-width: 849px) and (min-width: 600px) {
  header div.head {
    width: 75%;
    padding-top: 120px;
    grid-template-columns: 1fr;
  }
  header div.head h1 {
    font-size: 3rem;
    margin-bottom: 50px;
  }

  main section {
    width: 90%;
    padding: 90px 0;
    margin: 0px auto 50px auto;
    display: grid;
    grid-template-columns: 1fr;
  }
  main section div p {
    padding: 0px 90px;
  }
  main section ul {
    grid-template-columns: 1fr 1fr;
    padding: 20px;
  }

  div.services {
    display: grid;
    grid-template-columns: 1fr;
  }
  div.services div {
    width: 70%;
  }

  .services p.service-icon {
    font-size: 4.5rem;
    padding: 10px 30px;
    margin: 0px 0px 30px 0px;
  }
  .services div {
    margin: 35px auto 35px auto;
  }

  .services p.service-title {
    font-size: 2.1rem;
    padding: 0;
    margin: 30px;
  }

  .services p:last-of-type {
    padding: 0px 20px;
    text-align: left;
  }

  .gallery {
    height: 400px;
  }
}

@media (max-width: 599px) and (min-width: 0px) {
  nav {
    /* text-align: center; */
    display: grid;
    grid-template-columns: 1fr;
  }

  /* nav ul {
    /* margin-bottom: 10px; */
  /* nav ul:first-of-type {
    /* border-bottom:1px solid #111; */
  /* padding-bottom: 10px;  } 
  */
  .logo-container {
    display: flex;
    flex-direction: row-reverse;
  }

  .sidenav {
    top: 0;
    right: 0;
    left: auto;
  }

  .sidenav .closebtn {
    top: -15px;
    right: 15px;
    left: auto;
  }

  nav ul:last-of-type li {
    text-align: center;
    width: 100%;
    padding-top: 10px;
    margin-right: 40px;
  }

  nav ul:first-of-type li {
    font-size: 1.5em;
  }
  header {
    height: 100%;
    position: relative;
    /* background: none; */
  }

  header div.head {
    width: 90%;
    display: flex;
    padding-top: 35%;
    margin-bottom: 50px;
    flex-direction: column;
  }
  header div.head h1 {
    font-size: 2.2rem;
    border: none;
    margin-bottom: 50px;
  }

  header div.head p {
    padding-left: 0px;
  }

  .request-access {
    margin-bottom: 10px !important;
  }

  .request-access-form {
    flex-direction: column;
    width: 100%;
  }
  .contact {
    width: 100%;
    padding: 10px 0px 10px 0px;
    margin-left: 0px;
  }

  /* main section:first-of-type{
    /* margin-top: 390px; *} */

  main section {
    width: 90%;
    padding: 10px 0;
    margin: 0px auto 0px auto;
    display: grid;
    grid-template-columns: 1fr;
  }
  main section div p {
    padding: 0px 10px;
  }

  main section ul {
    grid-template-columns: 1fr;
    /* padding: 20px; */
  }

  main section img {
    width: 100%;
  }
  main section h2 {
    font-size: 2.9em;
    padding: 10px;
  }

  div.services {
    display: grid;
    grid-template-columns: 1fr;
    padding: 15px;
    margin-top: 70px;
    margin-bottom: 70px;
    grid-row-gap: 70px;
  }
  div.services div {
    width: 90%;
  }

  .services p.service-icon {
    font-size: 4.4rem;
    padding: 10px 30px;
    margin: 0px 0px 30px 0px;
  }
  .services p.service-title {
    font-size: 2rem;
    padding: 0;
    margin: 0px 25px 45px 25px;
  }

  .services p:last-of-type {
    padding: 0px 20px;
    text-align: left;
  }

  .gallery {
    grid-template-columns: 1fr;
    height: auto;
    margin: 30px auto 0px auto;
  }
  .gallery div {
    height: 200px;
  }

  .gallery div.gallery-item-one {
    grid-column-end: span 1;
    grid-row-end: span 1;
  }

  main section form div label {
    margin-right: 10px;
    border-radius: 5px;
  }

  main section form div:last-of-type input {
    padding: 10px;
    border-radius: 5px;
  }
  footer {
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    font-size: 0.8em;
  }
  footer ul li a {
    font-size: 1rem;
  }

  .footer-contact {
    padding: 8px 0px 8px 0px;
  }

  footer ul {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .locateme {
    width: 100%;
  }

  /* #mission {
    display: none;
  }
  #contact {
    display: none;
  }
  .intro {
    display: none;
  } */
  /* .head { 
    display: none !important;
  } */

  .react-quiz-container {
    padding: 5px !important;
    width: 90% !important;
  }
}

.intro {
  width: 85% !important;
  max-width: 500px;
}

/* Ciruit Simulator */

.gwt-MenuBar-horizontal {
  display: none !important;
}
