.section3 {
  display: block;
  width: 100%;
  background-color: rgba(255, 209, 120, 0.1);
  align-items: center;
  text-align: center;
  height: 650px;
  padding-top: 10px;
}

.section3__Right > h1 {
  padding: 15px;
}

.section3__Right > h4 {
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  padding: 5px;
  margin-top: -20px;
  color: rgba(16, 28, 55, 0.7);
}

.time__icon {
  display: none;
}

.code__yellow {
  color: #ffd178;
}

.mainImage3 {
  width: 450px;
  object-fit: contain;
  margin-top: 30px;
  margin-bottom: -20px;
}

.image2 {
  display: none;
}

.section3__button {
  background: #ff9080 !important;
  border-radius: 8px !important;
  color: white !important;
  width: 207px !important;
  text-transform: none !important;
  margin-top: 12px !important;
  padding: 12px 16px !important;
}

@media only screen and (max-width: 420px) {
  .mainImage3 {
    width: 300px;
  }
}

@media only screen and (min-width: 580px) {
  .section3 {
    height: 735px;
  }
  .mainImage3 {
    width: 550px;
  }
}

@media only screen and (min-width: 700px) {
  .section3 {
    height: 780px;
  }
}

@media only screen and (min-width: 1100px) {
  .section3 {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 736px;
  }
  .time__icon {
    display: block;
  }
  .section3__Right {
    text-align: start;
  }
  .section3__Right > h1 {
    margin-top: 20px;
    margin-left: -10px;
    font-size: 44px;
    width: 452px;
  }
  .yellow__box {
    height: 50px;
    width: 50px;
    background: #ffd178;
    box-shadow: 0px 5px 12px rgba(149, 184, 254, 0.4);
    border-radius: 18px;
    display: flex;
    color: white;
    justify-content: center;
    align-items: center;
    margin-bottom: -20px;
    margin-left: 5px;
  }

  .mainImage3 {
    width: 50%;
    margin-top: 20px;
  }
  .arrows {
    display: block;
  }
}
