.banner {
  text-align: center;
  background: linear-gradient(180deg, #ffffff 42.06%, #f5f9ff 86.82%);
  margin-top: 40px;
}
.banner__title {
  letter-spacing: -0.01em;
  line-height: 50px;
  font-size: 25px;
  position: relative;
}

.arrow__icon {
  display: none;
}


.banner__subTitle {
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  padding: 10px;
  margin-top: -10px;
  line-height: 24px;
  text-align: center;
}
.banner__button {
  background-color: #ff9080 !important;
  color: white !important;
  font-weight: 200 !important;
  text-transform: none !important;
  margin-left: 10px !important;
  margin-top: 0px !important;
  width: 250px;
  height: 50px;
}

.personalized {
  color: #ff9080;
  border: 1px solid red;
  padding-right: 15px;
  padding-left: 10px;
  background-color: #fff6f4;
  margin-right: 5px;
}

.form {
  float: none;
  margin: 0 auto;
  display: flex;
  width: 450px;
  height: 60px;
  justify-content: center;
}

@media only screen and (max-width: 280px) {
  .banner__title {
    font-size: 20px;
    line-height: 40px;
  }
  .banner__button {
    width: 80px;
    font-size: 10px !important;
    height: 50px;
  }
  .email__input {
    width: 150px !important;
  }
}

@media only screen and (min-width: 320px) {
  .banner__title {
    font-size: 22px;
  }
}

@media only screen and (min-width: 411px) {
  .banner__title {
    font-size: 30px;
  }
}

@media only screen and (max-width: 649px) {
  .form {
    width: 300px;
    flex-direction: column;
    align-items: center;
    padding: 40px 0px;
  }
  .banner__title {
    font-size: 24px;
    line-height: 36px;
  }
  .email__input {
    margin-bottom: 6px !important;
  }
  .banner > .form .email__input {
    width: 100% !important;
  }
  .banner__button {
    width: 100%;
    margin-left: 0px !important;
  }
}

@media only screen and (min-width: 650px) and (max-width: 799px) {
  .arrow__icon {
    display: none;
  }
  .banner__title {
    font-size: 40px;
    line-height: 70px;
  }
}

@media only screen and (min-width: 800px) {
  .banner__title {
    font-size: 44px;
    line-height: 70px;
  }
  .arrow__icon {
    display: inline;
    position: absolute;
    margin-left: -6px;
    bottom: 52px;
  }
}

@media only screen and (min-width: 920px) {
  .banner {
    background: linear-gradient(180deg, #ffffff 42.06%, #f5f9ff 86.82%);
  }

  .banner__title {
    align-items: center;
    text-align: center;
    padding: 10px;
    letter-spacing: -0.01em;
    font-style: normal;
    line-height: 90px;
    position: relative;
    font-size: 65px;
    float: none;
    margin: 0 auto;
    width: 900px;
  }

  .banner__subTitle {
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    text-align: center;
    margin-top: 20px;
  }

  .personalized {
    color: #ff9080;
    border: 1px solid red;
    padding: 4px;
    padding-right: 15px;
    padding-left: 15px;
    background-color: #fff6f4;
    margin-right: 15px;
  }

  .arrow__icon {
    display: inline;
    position: absolute;
    margin-left: -16px;
    bottom: -14px;
  }

  .form {
    display: flex;
    width: 500px;
    height: 60px;
    justify-content: center;
  }
}
