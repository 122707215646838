.footer {
  width: 95%;
  height: 318px;
  background: #101c37;
  border-radius: 10px;
  display: flex;
  text-align: center;
  margin: 20px auto;
}

.footer__ready {
  color: #ff9080;
}

.eye_iconFooter {
  display: none;
}

.footer__left {
  float: none;
  margin: 0 auto;
}

.footer__company {
  color: darkgray;
}

.footer__left > h1 {
  height: 14px;
  font-weight: bold;
  font-size: 30px;
  line-height: 34px;
  color: white;
  padding: 10px;
}

.footer__left > p {
  height: 24px;
  font-size: 15px;
  font-weight: 500;
  color: rgba(255, 255, 255, 0.7);
  padding: 10px;
  margin-top: 40px;
  text-align: center;
}
.footer__right {
  display: none;
}

.footer__right > h1 {
  font-family: 'DM Sans', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 32px;
  line-height: 50px;
  letter-spacing: -0.03em;
}

.email__input {
  color: black !important;
  background-color: white !important;
  width: 70%;
  border-radius: 4px;
  padding: 0px !important;
  margin-bottom: 12px !important;
  margin-right: 1px !important;
}

.footer__button {
  width: 70%;
  padding: 10px !important;
  font-size: 15px !important;
  background-color: #ff9080 !important;
  color: white !important;
  font-weight: 200 !important;
  text-transform: none !important;
}

@media only screen and (max-width: 280px) {
  .footer__left > h1 {
    font-size: 23px;
  }
}

@media only screen and (min-width: 1000px) {
  .footer {
    height: 608px;
  }
  .footer__left {
    flex: 0.4;
    margin: 98px 50px;
    text-align: start;
  }

  .eye_iconFooter {
    display: block;
  }

  .red__boxFooter {
    height: 50px;
    width: 50px;
    background: #ff9080;
    border-radius: 18px;
    display: flex;
    color: white;
    justify-content: center;
    align-items: center;
    margin-bottom: 0px;
    margin-left: 10px;
  }

  .arrows {
    display: block;
  }

  .footer__left > h1 {
    font-style: normal;
    font-weight: bold;
    font-size: 34px;
    line-height: 50px;
    letter-spacing: -0.03em;
  }

  .footer__left > p {
    height: 30px;
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    color: rgba(255, 255, 255, 0.7);
    margin-top: 70px;
    text-align: start;
  }
  .footer__right {
    flex: 0.6;
    display: flex;
    margin: 80px 20px;
    padding-right: 20px;
    color: white;
    height: 300px;
    text-align: start;
    position: relative;
  }
  .email__input {
    color: black !important;
    background-color: white !important;
    width: 90%;
    margin-bottom: 6px !important;
  }

  .footer__button {
    width: 90%;
    padding: 15px !important;
    font-size: 15px !important;
    background-color: #ff9080 !important;
    color: white !important;
    font-weight: 200 !important;
    text-transform: none !important;
  }
  .footer__bio {
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 8px;
    margin-top: 100px;
    position: static;
    border: 1px solid transparent;
  }

  .footer__bio > img {
    margin-right: 20px;
  }

  .person__big {
    border-radius: 40px;
    border: 2px solid white;
    position: relative;
  }
}

@media only screen and (max-width: 1024px) {
  .footer__bio {
    margin-top: 50px;
  }
}

@media only screen and (min-width: 1300px) {
  .footer__left > h1 {
    font-size: 44px;
  }
}

@media only screen and (min-width: 1400px) {
  .footer__right > h1 {
    font-size: 44px;
  }
}
