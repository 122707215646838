.header {
  display: flex;
  align-items: center;
  margin: 10px;
  margin-left: 10px;
  margin-top: 10px;
}

.nav {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  margin-right: 10px;
  cursor: pointer;
  font-size: 14px;
}

.nav__item {
  font-size: 10px;
  margin-right: 10px;
}

.nav__item1 {
  background-color: rgba(255, 209, 120, 0.4);
  padding: 12px;
  border-radius: 8px;
  font-size: 10px;
}

.logo__image {
  height: 48px;
  margin-left: 20px;
  width: 48px;
}

.title {
  display: none;
}

@media only screen and (max-width: 280px) {
  .nav__item {
    font-size: 9px;
  }
  .logo__image {
    margin-left: 0px;
  }

  .nav__item1 {
    font-size: 9px;
  }
}

@media only screen and (min-width: 400px) {
  .nav__item {
    font-size: 15px;
  }

  .nav__item1 {
    font-size: 15px;
  }

}

@media only screen and (min-width: 820px) {
  .header {
    display: flex;
    align-items: center;
    margin: 10px;
    margin-top: 10px;
  }

  .nav__item {
    margin-right: 40px;
    font-size: 16px;
  }

  .nav__item1 {
    margin-right: 104px;
    font-size: 16px;
  }

  .title {
    margin-left: 18px;
    font-size: 16px;
    display: inline;
  }

  .logo__image {
    margin-left: 104px;
    height: 48px;
    width: 48px;
  }
}

@media only screen and (max-width: 420px) {
  .logo__image {
    width: 36px;
    height: 36px;
  }
  .nav__item {
    display: none;
  }
}
