.section1 {
  display: block;
  width: 100%;
  background-color: #f5f9ff;
  align-items: center;
  text-align: center;
  height: 640px;
  padding-top: 10px;
}

.section1__Right > h1 {
  padding: 10px;
}

.section1__Right > h4 {
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  padding: 5px;
  margin-top: -20px;
  color: rgba(16, 28, 55, 0.7);
}

.video {
  width: 80%;
}

.main-video {
  width: 100%;
  max-width: 620px;
  float: none;
  margin: 0 auto;
}

.blue__box {
  display: none;
}

.arrows {
  margin-top: 5px;
}

.arrows__Left {
  margin-right: 10px;
}

.code__blue {
  color: #95b8fe;
}

.section1__button {
  background: #ff9080 !important;
  border-radius: 8px !important;
  color: white !important;
  width: 207px !important;
  text-transform: none !important;
  padding: 12px 16px !important;
}

@media only screen and (min-width: 1100px) {
  .section1 {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 736px;
  }
  .section1__Right {
    text-align: start;
  }
  .section1__Right > h1 {
    font-size: 44px;
    width: 452px;
  }

  .blue__box {
    height: 50px;
    width: 50px;
    background: #95b8fe;
    box-shadow: 0px 5px 12px rgba(149, 184, 254, 0.4);
    border-radius: 18px;
    display: flex;
    color: white;
    justify-content: center;
    align-items: center;
    margin-bottom: -20px;
    margin-left: 5px;
  }
  .video {
    width: 100%;
  }
  .main-video {
    height: 528px;
    margin: 20px;
  }
}
