.section2 {
  display: block;
  width: 100%;
  background-color: white;
  align-items: center;
  text-align: center;
  height: 730px;
}

.section2__Right > h1 {
  margin-top: 30px;
  font-size: 35px;
  padding: 10px;
}

.section2__Right > h4 {
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  padding: 5px;
  margin-top: 20px;
  color: rgba(16, 28, 55, 0.7);
}

.section2__Right > hr {
  width: 90%;
}

.section2__Right > p {
  font-style: normal;
  font-weight: normal;
  padding: 20px;
  font-size: 16px;
  line-height: 18px;
  color: rgba(16, 28, 55, 0.7);
}

.quote {
  text-align: start;
}

.quote__img {
  display: flex;
}

.quote__bio2 {
  display: flex;
  align-items: center;
  justify-content: center;
}

.quote__name {
  margin-left: 15px;
  line-height: 10px;
}

.quote__company {
  color: rgba(16, 28, 55, 0.7);
}

.code__green {
  color: #54e6cc;
}

.mainImage2 {
  width: 250px;
  object-fit: contain;
  margin-top: 30px;
}

.image2 {
  object-fit: contain;
  margin-top: 0px;
  height: 50px;
}

.person__icon {
  display: none;
}

@media only screen and (min-width: 280px) {
  .mainImage2 {
    width: 300px;
  }
  .section2 {
    height: 800px;
  }
}

@media only screen and (min-width: 500px) {
  .mainImage2 {
    width: 500px;
  }
}

@media only screen and (min-width: 990px) {
  .section2 > h1 {
    font-size: 30px;
    text-align: center;
  }
  .mainImage2 {
    width: 500px;
  }
}

@media only screen and (min-width: 1100px) {
  .section2 {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .person__icon {
    display: block;
  }
  .section2__Right {
    text-align: start;
    height: 500px;
  }
  .section2__button {
    margin-top: 100px !important;
  }
  .section2__Right > h1 {
    font-size: 44px;
    margin-top: -10px;
    width: 452px;
  }
  .section2__Right > hr {
    width: 100%;
    margin-top: 100px;
  }

  .section2__Right > p {
    width: 450px;
    padding: 0px;
  }

  .green__box {
    height: 50px;
    width: 50px;
    background: #54e6cc;
    box-shadow: 0px 5px 12px rgba(149, 184, 254, 0.4);
    border-radius: 18px;
    display: flex;
    color: white;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
    margin-left: 10px;
  }
  .quote__bio2 {
    justify-content: start;
  }
  .mainImage2 {
    width: 650px;
  }
}

@media only screen and (max-width: 1100px) {
  .section2__Right > p {
    text-align: center;
  }
}

@media only screen and (min-width: 1630px) {
  .image1 {
    object-fit: contain;
    margin-top: 20px;
  }
}
