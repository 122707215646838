.section4 {
  display: block;
  width: 100%;
  background-color: white;
  align-items: center;
  text-align: center;
  height: 736px;
}

.section4__Right > h1 {
  margin-top: 30px;
  padding: 15px;
}

.section4__Right > h4 {
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  padding: 5px;
  margin-top: 20px;
  color: rgba(16, 28, 55, 0.7);
}

.section4__Right > hr {
  width: 90%;
}

.section4__Right > p {
  font-style: normal;
  font-weight: normal;
  padding: 20px;
  font-size: 16px;
  line-height: 18px;
  color: rgba(16, 28, 55, 0.7);
}

.quote {
  text-align: start;
}

.quote__img {
  display: flex;
}

.quote__bio4 {
  display: flex;
  align-items: center;
  justify-content: center;
}

.quote__name {
  margin-left: 15px;
  line-height: 10px;
}

.quote__company {
  color: rgba(16, 28, 55, 0.7);
}

.code__red {
  color: #ff9080;
}

.mainImage4 {
  width: 450px;
  object-fit: contain;
}
.eye__icon {
  display: none;
}

.image4 {
  display: none;
}

@media only screen and (max-width: 420px) {
  .mainImage4 {
    width: 300px;
  }
  .section4 {
    height: 500px;
  }
}

@media only screen and (min-width: 700px) {
  .mainImage4 {
    width: 550px;
    object-fit: contain;
    margin-top: 40px;
  }
}

@media only screen and (min-width: 990px) {
  .section4 > h1 {
    font-size: 30px;
    text-align: center;
  }
}

@media only screen and (min-width: 1100px) {
  .section4 {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .section4__Right {
    text-align: start;
    height: 500px;
  }
  .section4__button {
    margin-top: 100px !important;
  }
  .section4__Right > h1 {
    margin-top: -10px;
    font-size: 44px;
    width: 452px;
  }

  .section4__Right > hr {
    width: 100%;
    margin-top: 100px;
  }

  .section4__Right > p {
    width: 450px;
    padding: 0px;
  }
  .mainImage4 {
    width: 50%;
    margin-top: 20px;
  }

  .red__box {
    height: 50px;
    width: 50px;
    background: #ff9080;
    box-shadow: 0px 5px 12px rgba(149, 184, 254, 0.4);
    border-radius: 18px;
    display: flex;
    color: white;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
    margin-left: 10px;
  }
  .eye__icon {
    display: block;
  }
  .quote__bio4 {
    justify-content: start;
  }
}

@media only screen and (max-width: 1100px) {
  .section4__Right > p {
    text-align: center;
  }
}

@media only screen and (min-width: 1630px) {
  .image4 {
    object-fit: contain;
    margin-top: 20px;
  }
}
