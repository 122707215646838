.isSpotlight {
	position: relative;
	display: inline-block;
	width: 41px;
	height: 18px;
	margin: 15px 5px;
  }
  
  .isSpotlight input { 
	opacity: 0;
	width: 0;
	height: 0;
  }
  
  .slider {
	position: absolute;
	cursor: pointer;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: #ccc;
	-webkit-transition: .4s;
	transition: .4s;
  }
  
  .slider:before {
	position: absolute;
	content: "";
	height: 14px;
	width: 14px;
	left: 3px;
	bottom: 2px;
	background-color: white;
	-webkit-transition: .4s;
	transition: .4s;
  }
  
  input:checked + .slider {
	background-color: #578FFF;
  }
  
  input:focus + .slider {
	box-shadow: 0 0 1px #578FFF;
  }
  
  input:checked + .slider:before {
	-webkit-transform: translateX(21px);
	-ms-transform: translateX(21px);
	transform: translateX(21px);
  }
  
  /* Rounded sliders */
  .slider.round {
	border-radius: 34px;
  }
  
  .slider.round:before {
	border-radius: 50%;
  }
