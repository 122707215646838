.mini__footer {
  font-size: 12px;
  background-color: #f5f9ff;
  text-align: center;
  align-items: center;
  padding: 5px;
  height: 100%;
  display: block;
}

.flowdash {
  font-size: 20px;
  line-height: 20px;
  font-weight: 500;
}

@media only screen and (min-width: 600px) {
  .mini__footer {
    display: flex;
    font-size: 16px;
    justify-content: space-around;
  }
  .mini__footerLeft {
    display: flex;
    margin-left: 40px;
  }
  .privacy {
    margin-left: 40px;
  }
  .access {
    margin-right: 20px;
  }
  .mini__footerRight {
    display: flex;
    margin-right: 40px;
  }
}
