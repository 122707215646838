.multi__language {
  text-align: center;
  margin-top: 50px;
  line-break: break-all;
}

.multi__language > h1 {
  padding: -10px;
  font-size: 34px;
  margin-top: -10px;
}

.multi__languageTitle {
  color: #54e6cc;
  font-size: 34px;
}

.multi__languageSubTitle {
  font-family: 'DM Sans', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  padding-left: 30px;
  padding-right: 30px;
  margin-bottom: 80px;
  color: rgba(16, 28, 55, 0.7);
}

@media only screen and (min-width: 708px) {
  .multi__language {
    text-align: center;
    margin-top: -10px;
    line-break: break-all;
    padding: 20px;
  }
  .multi__language {
    margin-top: 150px;
  }
}

@media only screen and (min-width: 768px) {
  .multi__language {
    margin-top: 150px;
  }
  .multi__language > h1 {
    font-size: 44px;
  }
  .multi__languageTitle {
    font-size: 44px;
  }
}

@media only screen and (min-width: 1100px) {
  .multi__language {
    margin-left: 200px;
    margin-right: 200px;
  }
}
